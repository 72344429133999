import React from "react";
import { Wogo } from "../assets";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const Payslip = () => {
  const handleDownload = async () => {
    const pdfContainer = document.getElementById("cnt");

    html2canvas(pdfContainer).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("payslip.pdf");
    });
  };

  return (
    <>
      <div className="grid place-items-end h-auto">
        <button
          className="bg-[#03C9D7] hover:bg-[#03C9D7] text-center text-white font-bold py-2 px-4 rounded-lg mb-9"
          onClick={handleDownload}
        >
          Download Payslip
        </button>
      </div>
      <div id="cnt" className="bg-gray-100 p-9">
        <div className="container mx-auto py-10">
          <h1 className="text-4xl text-center mb-3">Welcome to Your Payslip</h1>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div
              className="bg-white p-4 shadow border-2 border-black h-auto	"
              // style={{ height: "calc(100vh * 1.5)" }}
              id="pdf"
            >
              <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                <h1 className="text-2xl md:text-3xl font-semibold">
                  Payslip For:
                </h1>
                <div className="text-xl border p-2 w-full md:w-8/12">
                  <h3>Municipality: Georage local Municipality (Area A)</h3>
                  <h3>Minimum Wage: R15.00 per hour</h3>
                </div>
              </div>

              <div className="p-4 text-xl">
                <div className="mb-3 md:flex md:gap-5">
                  <h2 className="w-1/3">Employee Name:</h2>
                  <h2 className="w-2/3">Vuyelwa Elizabeth Venkile</h2>
                </div>
                <div className="mb-3 md:flex md:gap-5">
                  <h2 className="w-1/3">ID Number:</h2>
                  <h2 className="w-2/3">0005084121089</h2>
                </div>
                <div className="mb-3 md:flex md:gap-5">
                  <h2 className="w-1/3">Occupation:</h2>
                  <h2 className="w-2/3">Care Worker</h2>
                </div>
              </div>
              <div className="p-4 text-xl">
                <div className="mb-3 md:flex md:gap-5">
                  <h2 className="w-1/3">Employer Name:</h2>
                  <h2 className="w-2/3">Stephan vender merwe</h2>
                </div>
                <div className="mb-3 md:flex md:gap-5">
                  <h2 className="w-1/3">UIF Number:</h2>
                  <h2 className="w-2/3">5555</h2>
                </div>
              </div>
              <div className="p-4 text-xl">
                <div className="mb-3 md:flex md:gap-5">
                  <h2 className="w-1/3">Employee Name:</h2>
                  <h2 className="w-2/3">Vuyelwa Elizabeth Venkile</h2>
                </div>
                <div className="mb-3 md:flex md:gap-5">
                  <h2 className="w-1/3">Pay Period:</h2>
                  <h2 className="w-2/3">From 2019/01/05 To 2019/01/18</h2>
                </div>
                <div className="mb-3 md:flex md:gap-5">
                  <h2 className="w-1/3">Payment Date:</h2>
                  <h2 className="w-2/3">2019/01/18</h2>
                </div>
              </div>
              <div className="table-container overflow-x-auto">
                <table className="table-auto border-l-1 border-black border-b-2 w-full text-xl mb-9">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 text-left" colSpan="2">
                        Income
                      </th>
                      <th className="px-4 py-2 text-left" colSpan="2">
                        Deductions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-4">Basic Wage</td>
                      <td className="px-4">R 2000.00</td>
                      <td className="px-4">Loan Repayment</td>
                      <td className="px-4">R 0.00</td>
                    </tr>
                    <tr>
                      <td className="px-4">Overtime</td>
                      <td className="px-4">R 0.00</td>
                      <td className="px-4">Employee UIF</td>
                      <td className="px-4">R 20.00</td>
                    </tr>
                    <tr>
                      <td className="px-4">Sunday Work</td>
                      <td className="px-4">R 0.00</td>
                      <td className="px-4">Others</td>
                      <td className="px-4">R 0.00</td>
                    </tr>
                    <tr>
                      <td className="px-4">Public Holiday Work</td>
                      <td className="px-4">R 0.00</td>
                      <td className="px-4 font-bold">Total:</td>
                      <td className="px-4 font-bold">R 20.00</td>
                    </tr>
                    <tr>
                      <td className="px-4">Transport</td>
                      <td className="px-4">R 0.00</td>
                    </tr>
                    <tr>
                      <td className="px-4">Gifts</td>
                      <td className="px-4">R 0.00</td>
                      <td className="px-4 font-bold">
                        Employers contributions
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4">Loan Given</td>
                      <td className="px-4">R 0.00</td>
                      <td className="px-4">Employer UIF</td>
                      <td className="px-4">R 20.00</td>
                    </tr>
                    <tr>
                      <td className="px-4">Other</td>
                      <td className="px-4">R 0.00</td>
                      <td className="px-4 font-bold">Total:</td>
                      <td className="px-4 font-bold">R 20.00</td>
                    </tr>
                    <tr>
                      <td className="px-4 font-bold">Total:</td>
                      <td className="px-4 font-bold">R 20.00</td>
                    </tr>
                    <tr>
                      <td className="px-4 font-bold" colSpan="2">
                        Total Amount Due:
                      </td>
                      <td className="px-4 font-bold text-right" colSpan="2">
                        R 1980.00
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 font-bold mb-3" colSpan="2">
                        Total Amount Pay:
                      </td>
                      <td
                        className="px-4 font-bold text-right mb-3"
                        colSpan="2"
                      >
                        R 1980.00
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table-auto border-l-1 border-black border-b-2 w-full text-xl mb-9">
                  <thead>
                    <tr>
                      <th className="px-4 py-2" colSpan="2"></th>
                      <th className="px-4 py-2 text-left">Hours</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-4">Annual Leave</td>
                      <td className="px-4">Balance Brought Forward</td>
                      <td className="px-4">0.00</td>
                    </tr>
                    <tr>
                      <td className="px-4"></td>
                      <td className="px-4">Accrued This Period</td>
                      <td className="px-4">3.82</td>
                    </tr>
                    <tr>
                      <td className="px-4"></td>
                      <td className="px-4">Taken This Period</td>
                      <td className="px-4">0.00</td>
                    </tr>
                    <tr>
                      <td className="px-4"></td>
                      <td className="px-4">Carried Forward</td>
                      <td className="px-4">3.82</td>
                    </tr>
                    <tr>
                      <td className="px-4" colSpan="3"></td>
                    </tr>
                    <tr>
                      <th className="px-4 py-2" colSpan="2"></th>
                      <th className="px-4 py-2 text-left">Hours Taken</th>
                    </tr>
                    <tr>
                      <td className="px-4" colSpan="2">
                        Sick Leave
                      </td>
                      <td className="px-4">0.00</td>
                    </tr>
                    <tr>
                      <td className="px-4" colSpan="2">
                        Family Responsibility Leave
                      </td>
                      <td className="px-4">0.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="logo-container flex justify-center">
                <img src={Wogo} alt="Wogo Logo" className="wogo-logo w-6/12" />
              </div>
              <div>
                <h1 className="text-xl text-center font-bold">
                  REPORT ANY IRREGULARITIES PER WHATSAPP: 082 871 9947
                </h1>
              </div>
            </div>

            <div id="additionalDiv" className="w-auto	bg-white p-4 shadow">
              <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                <h1 className="text-2xl md:text-3xl font-semibold">
                  Worksheet :
                </h1>
              </div>
              <table className="table-auto border-1 border-black w-full text-xl mb-9 py-2">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left mb-5">Date</th>
                    <th className="px-4 py-2 text-left mb-5">Hours</th>
                    <th className="px-4 py-2 text-left mb-5">Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-4">2019/01/08</td>
                    <td className="px-4">7.50</td>
                    <td className="px-4">Worked</td>
                  </tr>
                  <tr>
                    <td className="px-4">2019/01/09</td>
                    <td className="px-4">7.50</td>
                    <td className="px-4">Worked</td>
                  </tr>
                  <tr>
                    <td className="px-4">2019/01/10</td>
                    <td className="px-4">7.50</td>
                    <td className="px-4">Worked</td>
                  </tr>
                  <tr>
                    <td className="px-4">2019/01/11</td>
                    <td className="px-4">7.50</td>
                    <td className="px-4">Worked</td>
                  </tr>
                  <tr>
                    <td className="px-4">2019/01/12</td>
                    <td className="px-4">7.50</td>
                    <td className="px-4">Worked</td>
                  </tr>
                  <tr>
                    <td className="px-4">2019/01/13</td>
                    <td className="px-4">7.50</td>
                    <td className="px-4">Worked</td>
                  </tr>
                  <tr>
                    <td className="px-4">2019/01/14</td>
                    <td className="px-4">7.50</td>
                    <td className="px-4">Worked</td>
                  </tr>
                  <tr>
                    <td className="px-4">2019/01/15</td>
                    <td className="px-4">7.50</td>
                    <td className="px-4">Worked</td>
                  </tr>
                  <tr>
                    <td className="px-4">2019/01/16</td>
                    <td className="px-4">7.50</td>
                    <td className="px-4">Worked</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Payslip;
