import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

import Loader from "../components/loader";
import { Button } from "../components";
const main = process.env.REACT_APP_API_LIVE_ADMIN;
const base = main.replace("admin", "api");
const Report = () => {
  const [loading, setLoading] = useState(true);
  const [employer, setEmployer] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [wages, setWages] = useState([]);
  const [reports, setReport] = useState([]);
  const [wagesPreHistory, setWagesPreHistory] = useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [payReport, setPayReport] = useState([]);
  const [generateReport, setGenerateReport] = useState([]);
  const initialValues = {
    employerField: "",
    employeeField: "",
    reportFile: null,
  };

  const validationSchema = Yup.object().shape({
    employerField: Yup.string().required("Please select an employer"),
    employeeField: Yup.string().required("Please select an employee"),
    reportFile: Yup.mixed()
      .required("Please upload a report")
      .test("fileType", "Unsupported file format", (value) =>
        value
          ? [
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ].includes(value.type)
          : true
      ),
  });

  const fetchEmployerData = async (token) => {
    try {
      const response = await axios.get(main + "get-employee-by-employer", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const filteredEmployers = response?.data?.data?.filter(
        (employer) => employer !== null && employer !== undefined
      );
      setEmployer(filteredEmployers);
    } catch (error) {
      console.error("Error fetching employers", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchReportData = async (token) => {
    try {
      const response = await axios.get(main + "get-reports", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const filteredEmployers = response?.data?.data?.filter(
        (employer) => employer !== null && employer !== undefined
      );
      setReport(filteredEmployers);
    } catch (error) {
      console.error("Error fetching employers", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchWageData = async (token) => {
    try {
      const response = await axios.get(main + "get-wages", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const filteredEmployers = response?.data?.data?.filter(
        (employer) => employer !== null && employer !== undefined
      );
      setWages(filteredEmployers);
    } catch (error) {
      console.error("Error fetching employers", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchWagePreHistoryData = async (token) => {
    try {
      const response = await axios.get(main + "get-wagePreHistory", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const filteredEmployers = response?.data?.data?.filter(
        (employer) => employer !== null && employer !== undefined
      );
      setWagesPreHistory(filteredEmployers);
    } catch (error) {
      console.error("Error fetching employers", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    fetchEmployerData(token);
    fetchReportData(token);
    fetchWageData(token);
    fetchWagePreHistoryData(token);
  }, [showModal]);

  const handleEmployer = (e, formikProps) => {
    try {
      formikProps.handleChange(e);
      const desiredEmployerId = e.target.value;

      const desiredEmployer = employer.find(
        (employer) => employer.id === desiredEmployerId
      );

      if (desiredEmployer) {
        const employees = desiredEmployer.employee;
        for (let i = 0; i < employees.length; i++) {
          let wage = wages.find((x) => x.employeeId === employees[i]._id);
          let reg = wagesPreHistory.find(
            (x) => x.employeeId === employees[i]._id
          );
          let report = reports.filter((x) => x.employee === employees[i]._id);
          let date = "1981-01-01";
          let payDate = {};
          for (let l = 0; l < report?.length; l++) {
            if (new Date(date) < new Date(report[l].startDate)) {
              payDate = report[l];
              date = report[l].startDate;
            }
          }
          if (report?.length > 0) {
            let date1 = new Date().getTime();
            const date2 = new Date(payDate.startDate).getTime();
            const diffTime = Math.abs(date1 - date2);
            let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            employees[i].diffDays = diffDays;
            if (
              (employees[i].rate_of_pay === "Daily" ||
                employees[i].rate_of_pay === "Weekly") &&
              diffDays > 7
            )
              employees[i].generate = true;
            if (employees[i].rate_of_pay === "Fortnightly" && diffDays > 15)
              employees[i].generate = true;
            if (employees[i].rate_of_pay === "Monthly" && diffDays > 30)
              employees[i].generate = true;
          }
          employees[i].registration = reg?.registration_date;
          employees[i].rate_of_pay = wage?.rate_of_pay;
          employees[i].payDate = payDate;
          employees[i].report = report;
        }
        setEmployee(employees);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDateString = (date) =>
    new Date(date)
      ?.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      ?.replace(/ /g, "-");
  const generateReprt = (a) => {
    let diffDays = 0;
    let date1 = new Date().getTime();
    let date2 = 0;
    let data = [];
    let rateOfPay = a.rate_of_pay?.toLowerCase();
    if (a.report) setPayReport(a.report);
    setGenerateReport(data);
    // if (a.report?.length > 0) date2 = a.payDate?.startDate;else
    date2 = a.registration;
    let start = new Date(date2).getTime();
    const diffTime = start < date1 ? Math.abs(date1 - start) : 0;
    diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let length = 0;
    let day = 7;
    if (rateOfPay?.includes("fort") || rateOfPay?.includes("night")) day = 15;
    if (diffDays > 0) {
      let days = Math.ceil(diffDays / day) * day;
      if (days > diffDays) days = diffDays;
      length = Math.round(days / 7);
      let date3 = new Date(start);
      for (let i = 0; i < length; i++) {
        if (rateOfPay?.includes("month")) {
          if (i !== 0) date3.setDate(date3.getDate() + 1);
          let month = date3.getMonth();
          let year = date3.getFullYear();
          date3 = new Date(year, month + 1, 0);
        } else if (a.rate_of_pay) date3.setDate(date3.getDate() + day);
        if (new Date().getTime() < new Date(date3).getTime()) break;
        else {
          let day2 =
            date3.getFullYear() +
            "-" +
            (date3.getMonth() + 1) +
            "-" +
            date3.getDate();
          let toDate = new Date(date3).toDateString();
          const formattedDate = getDateString(date3);
          let link = `payslip-employee/${a._id}/${rateOfPay}/${day2}`;
          data.unshift({ day: formattedDate, day1: toDate, link: link });
        }
      }
      setGenerateReport(data);
      setView(false);
      setShowModal(true);
    }
  };

  const generatePayslip = (a) => {
    window.open(base + a);
  };

  const mainTrUI = () =>
    employee.map((a, j) => (
      <tr key={"t" + j}>
        <td className="border shadow-sm px-4 py-2 text-center">
          {a ? `${a?.first_name} ${a?.sur_name}` : "-"}
        </td>
        <td className="border shadow-sm px-4 py-2 text-center">
          {a ? a.rate_of_pay || "" : ""}
        </td>
        <td className="border shadow-sm px-4 py-2 text-center">
          {a?.registration ? getDateString(a.registration) : ""}
        </td>
        <td className="border shadow-sm px-4 py-2 text-center pdfdiv">
          {a.payDate && (
            <a href={base + a.payDate.link} data-replace="Download">
              <span>
                {a?.payDate?.startDate
                  ? getDateString(a.payDate.startDate)
                  : ""}
              </span>
            </a>
          )}
        </td>
        <td className="border shadow-sm px-4 py-2 text-center">
          <Button
            color="white"
            borderRadius="10px"
            bgColor={"#03C9D7"}
            text={"Generate Payslip"}
            onClick={() => generateReprt(a)}
          />
        </td>
      </tr>
    ));

  const tbodyUI = () => {
    let arr = [];
    for (let i = 0; i < payReport?.length; i++) {
      arr.push(new Date(payReport[i]?.startDate).toDateString());
    }
    return (
      <table className="border-collapse w-full mt-5">
        <thead>
          <tr>
            <th className="border shadow-sm px-4 py-2 bg-[#03C9D7] text-white">
              Payslip Genarate Date
            </th>
            <th className="border shadow-sm px-4 py-2 bg-[#03C9D7] text-white">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {generateReport.map((a, j) => (
            <tr key={"d" + j}>
              <td className="border shadow-sm px-4 py-2 text-center pdfdiv">
                {a.day}
              </td>
              <td className="border shadow-sm px-4 py-2 text-center">
                <Button
                  color="white"
                  bgColor={"#03C9D7"}
                  text={
                    arr.includes(a.day1)
                      ? "Download Payslip"
                      : "Generate Payslip"
                  }
                  borderRadius="10px"
                  onClick={() => generatePayslip(a.link)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="min-h-screen">
      <div className="w-full md:p-6 p-4 md:pt-4 pt-20 mb-10 pb-10">
        <h1 className="text-3xl font-extrabold tracking-tight text-slate-900">
          Employment Payslip Report
        </h1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form>
              <div className="lg:flex items-center justify-evenly bg-gray-200 h-auto p-3 mt-5 shadow-md">
                <div className="lg:mt-0 mt-2 mr-2">
                  <label
                    htmlFor="employerField"
                    className="block text-xl font-semibold text-gray-700 mr-2 mt-2"
                  >
                    Employer :
                  </label>
                  <Field
                    as="select"
                    id="employerField"
                    name="employerField"
                    className="mt-2 lg:w-96 w-full block py-2 px-3 border border-[#03C9D7] bg-white rounded-md shadow-sm focus:ring-[#03C9D7] focus:border-[#03C9D7] sm:text-sm"
                    onChange={(e) => handleEmployer(e, formikProps)}
                  >
                    <option value="" disabled>
                      Select an Employer
                    </option>
                    {employer?.map((option) => {
                      if (option?.name) {
                        return (
                          <option key={option?.id} value={option?.id}>
                            {option?.name}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </Field>

                  <ErrorMessage
                    name="employerField"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <table className="border-collapse w-full mt-5">
                    <thead>
                      <tr>
                        <th className="border shadow-sm px-4 py-2 bg-[#03C9D7] text-white">
                          Employee Name
                        </th>
                        <th className="border shadow-sm px-4 py-2 bg-[#03C9D7] text-white">
                          Rate of pay
                        </th>
                        <th className="border shadow-sm px-4 py-2 bg-[#03C9D7] text-white">
                          Registration Date
                        </th>
                        <th className="border shadow-sm px-4 py-2 bg-[#03C9D7] text-white">
                          Payslip Genarate Date
                        </th>
                        <th className="border shadow-sm px-4 py-2 bg-[#03C9D7] text-white">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>{mainTrUI()}</tbody>
                  </table>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
      <>
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      {view ? "Payslip list" : "Generate Payslip"}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        X
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative p-6 flex-auto overflow-y-auto scrollbar w-full max-h-96">
                    {tbodyUI()}
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="bg-[#03C9D7] text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    </div>
  );
};

export default Report;
