import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { loanListGrid } from "../data/dummy";
import { Header } from "../components";
import Loader from "../components/loader";

const LoanList = () => {
  const toolbarOptions = ["Search"];

  const editing = { allowDeleting: true, allowEditing: true };
  const [loading, setLoading] = useState(true);

  const [loanListData, setLoanListData] = useState();
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const headers = { Authorization: `Bearer ${token}` };
    axios
      .get(process.env.REACT_APP_API_LIVE_ADMIN + "get-loan", { headers })
      // .get("http://103.215.158.203:10314/admin/get-loan", { headers })
      .then((response) => {
        if (response?.data?.data) {
          const formattedData = response.data.data.map((item) => {
            const type =
              item.type === "Give Money" ? "Money Given" : "Money Received";
            return {
              ...item,
              employee: item.employee || "-",
              employeeId: item.employeeId || "-",
              type: type,
              amount:
                item.amount.toLocaleString("en-ZA", {
                  style: "currency",
                  currency: "ZAR",
                }) || "-",
              description: item.description || "-",
              date: item?.date ? new Date(item?.date) : null,
            };
          });
          setLoanListData(formattedData);
        }
      })
      .catch((error) => {
        if (error.response.data.status === 422) {
          toast.error(error?.response?.data?.error?.response?.error);
        } else {
          toast.error("Page not found!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl capitalize">
      <Header category="Page" title="Loan List" />
      {loading ? (
        <Loader />
      ) : (
        <GridComponent
          dataSource={loanListData}
          width="auto"
          allowPaging
          allowSorting
          pageSettings={{ pageCount: 5 }}
          editSettings={editing}
          toolbar={toolbarOptions}
          allowTextWrap
          allowMultiSorting
        >
          <ColumnsDirective>
            {loanListGrid.map((item, index) => (
              <ColumnDirective
                key={index}
                field={item.field}
                headerText={item.headerText}
                headerTextAlign="center"
                textAlign="center"
                width={item.width}
                format={item.format}
                type={item.type}
              />
            ))}
          </ColumnsDirective>
          <Inject services={[Search, Page, Sort, Toolbar]} />
        </GridComponent>
      )}

      <style>{`
      .e-headercell .e-headercelldiv  {
        font-size: 0.875rem;
        background-color: #03C9D7; 
        color: white; 
        text-align: left; 
      }
      .e-grid .e-headercell {
        background-color: #03C9D7;
        text-align: left; 
      }
      .e-grid .e-rowcell {
        text-align: left; 
      }

      .e-pager .e-numericcontainer .e-active {
        background-color: #03C9D7;
        color: white;
      }
      `}</style>
    </div>
  );
};

export default LoanList;
