import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { CalendarComponent } from "../Calendar";
import ForgotPassword from "../ForgotPassword";
import Login from "../Login";
import MasterSetting from "../MasterSetting";
import Profile from "../Profile";
import ResetPasswordForm from "../ResetPassword";
import AppLayout from "../layouts/AppLayout";
import { Dashboard, Employer } from "../pages";
import Employee from "../pages/Employee";
import GiftList from "../pages/Gift";
import LoanList from "../pages/Loan";
import TransportListing from "../pages/Transport";
import Payslip from "../pages/Payslip";
import PersonDetail from "../pages/PersonalDetails";
import NotesList from "../pages/Notes";
import Report from "../pages/Report";
import EmployeePayslip from "../pages/employee-payslip";

const AppRoutes = () => {
  const authenticatedRoutes = useRoutes(
    localStorage.getItem("authToken")
      ? [
          {
            path: "/dashboard",
            element: <Dashboard />,
          },
          {
            path: "/employers",
            element: <Employer />,
          },
          {
            path: "/employers/:id",
            element: <PersonDetail />,
          },
          {
            path: "/employee",
            element: <Employee />,
          },
          {
            path: "/loan",
            element: <LoanList />,
          },
          {
            path: "/gift",
            element: <GiftList />,
          },
          {
            path: "/masterSetting",
            element: <MasterSetting />,
          },
          {
            path: "/calendar",
            element: <CalendarComponent />,
          },
          {
            path: "/transport",
            element: <TransportListing />,
          },
          {
            path: "/payslip",
            element: <Payslip />,
          },
          {
            path: "/employee-payslip",
            element: <EmployeePayslip />,
          },
          {
            path: "/notes",
            element: <NotesList />,
          },
          {
            path: "/report",
            element: <Report />,
          },
          {
            path: "/profile",
            element: <Profile />,
          },
          {
            path: "/",
            element: <Navigate to="/dashboard" replace />,
          },
          {
            path: "*",
            element: <Navigate to="/dashboard" replace />,
          },
        ]
      : [
          {
            path: "/login",
            element: <Login />,
          },
          {
            path: "/forgotPassword",
            element: <ForgotPassword />,
          },
          {
            path: "/resetPassword",
            element: <ResetPasswordForm />,
          },
          {
            path: "*",
            element: <Navigate to="/login" replace />,
          },
          {
            path: "/",
            element: <Navigate to="/login" replace />,
          },
        ]
  );

  return <AppLayout>{authenticatedRoutes}</AppLayout>;
};

export default AppRoutes;
