import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "./resetPassword.css";

const ResetPasswordForm = () => {
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const handleOnPasswordChange = async (values) => {
    // .post("http://103.215.158.203:10314/admin/reset-password", {
    await axios
      .post(process.env.REACT_APP_API_LIVE_ADMIN + "reset-password", {
        password: values.password,
      })
      .then((response) => {
        toast(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        if (error.response.data.status === 422) {
          toast.error(error?.response?.data?.error?.response?.error);
        } else {
          toast.error("Page not found!");
        }
      });
  };

  return (
    <div className="login-container flex justify-center items-center h-screen">
      <div className="login-box bg-white rounded-lg p-8 shadow-md">
        <h2 className="wl-title text-2xl mb-4">Add Your New Password!</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleOnPasswordChange}
        >
          <Form>
            <div className="form-group mt-3">
              <label className="form-label text-gray-700">Password</label>
              <Field
                type="password"
                name="password"
                className="form-input-control mt-1 p-2 border border-gray-300 rounded"
                placeholder="Enter password"
              />
              <ErrorMessage
                name="password"
                component="div"
                className="error-message"
              />
            </div>
            <div className="form-group mt-3">
              <div className="flex items-center justify-between">
                <label className="form-label text-gray-700">
                  Confirm Password
                </label>
              </div>
              <Field
                type="password"
                name="confirmPassword"
                className="form-input-control mt-1 p-2 border border-gray-300 rounded"
                placeholder="Enter confirm Password"
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="error-message"
              />
            </div>
            <div className="text-center mt-3">
              <div className="mt-5">
                <button
                  type="submit"
                  style={{ backgroundColor: "#03C9D7" }}
                  className={`text-white p-3 w-full hover:drop-shadow-xl rounded-md reset-button`}
                >
                  Reset
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
