import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../components/loader";

const MasterSetting = () => {
  const token = localStorage.getItem("authToken");
  const [minWage, setMinWage] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get(
          process.env.REACT_APP_API_LIVE_ADMIN + "get-minimum-wage",
          // "http://103.215.158.203:10314/admin/get-minimum-wage",
          { headers }
        );
        const data = response?.data?.data;
        const lastObject = data[data.length - 1];
        const wage = lastObject?.wage;
        const start_date = lastObject?.start_date;
        const end_date = lastObject?.end_date;
        setMinWage(wage);
        setStartDate(new Date(start_date));
        setEndDate(new Date(end_date));
        setLoading(false);
      } catch (error) {
        console.error(error, "error");
      }
    };

    fetchData();
  }, [token]);

  const initialValues = {
    minimumWage: minWage || "",
  };

  const onSubmit = async (values) => {
    const headers = { Authorization: `Bearer ${token}` };
    let wage = values.minimumWage + 0.001;

    const minimumWageDetails = {
      wage: wage,
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_LIVE_ADMIN + "add-minimum-wage",
        // "http://103.215.158.203:10314/admin/add-minimum-wage",
        minimumWageDetails,
        { headers }
      );
      toast(response?.data?.data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error(error, "error");
      if (error?.response?.status === 404) {
        toast.error(error?.response?.data[0]);
      } else {
        toast.error("Page not found!");
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.minimumWage) {
      errors.minimumWage = "Minimum wage is required";
    }
    return errors;
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 lg:m-0 m-4">
      {loading ? (
        <Loader />
      ) : (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h1 className="text-2xl mb-4">Wages Calculation Master Setting</h1>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validate={validate}
          >
            <Form>
              <div className="mb-4 flex">
                <div className="w-1/2 pr-2">
                  <label
                    htmlFor="startDate"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Start Date:
                  </label>
                  <DatePicker
                    id="startDate"
                    name="startDate"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <div className="w-1/2 pl-2">
                  <label
                    htmlFor="endDate"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    End Date:
                  </label>
                  <DatePicker
                    id="endDate"
                    name="endDate"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="minimumWage"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Minimum Wage:
                </label>
                <Field
                  type="number"
                  id="minimumWage"
                  name="minimumWage"
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  style={{ borderColor: "#03C9D7" }}
                />
                <ErrorMessage
                  name="minimumWage"
                  component="div"
                  className="text-red-500 text-xs italic"
                />
              </div>
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  className="bg-[#03C9D7] hover:bg-[#02B5C3] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Submit
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      )}
    </div>
  );
};

export default MasterSetting;
