import React from "react";

const Footer = () => (
  <footer className="lg:fixed lg:bottom-0 left-0 z-20 w-full p-4 bg-white border-t border-gray-200 shadow flex items-center justify-between  dark:bg-gray-800 dark:border-gray-600">
    <span className="text-sm text-gray-700 text-center">
      ©2023 WOGO admin panel
    </span>
  </footer>
);

export default Footer;
