import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

import Loader from "../components/loader";
import { Button } from "../components";

const Report = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [employer, setEmployer] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedEmployer, setSelectedEmployer] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const initialValues = {
    employerField: "",
    employeeField: "",
    reportFile: null,
  };

  const validationSchema = Yup.object().shape({
    employerField: Yup.string().required("Please select an employer"),
    employeeField: Yup.string().required("Please select an employee"),
    reportFile: Yup.mixed()
      .required("Please upload a report")
      .test("fileType", "Unsupported file format", (value) =>
        value
          ? [
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ].includes(value.type)
          : true
      ),
  });

  const handleSubmit = (values) => {
    setData(values);
  };

  const fetchEmployerData = async (token) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_LIVE_ADMIN + "get-employee-by-employer",
        // "http://103.215.158.203:10314/admin/get-employee-by-employer",
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const filteredEmployers = response?.data?.data?.filter(
        (employer) => employer !== null && employer !== undefined
      );
      setEmployer(filteredEmployers);
    } catch (error) {
      console.error("Error fetching employers", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    fetchEmployerData(token);
  }, []);

  return (
    <div className="min-h-screen">
      <div className="w-full md:p-6 p-4 md:pt-4 pt-20">
        <h1 className="text-3xl font-extrabold tracking-tight text-slate-900">
          Details/Employment Equity Report
        </h1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form>
              <div className="lg:flex items-center justify-evenly bg-gray-200 h-auto p-3 mt-5 shadow-md">
                <div className="lg:mt-0 mt-2 mr-2">
                  <label
                    htmlFor="employerField"
                    className="block text-xl font-semibold text-gray-700 mr-2 mt-2"
                  >
                    Employer :
                  </label>
                  <Field
                    as="select"
                    id="employerField"
                    name="employerField"
                    className="mt-2 lg:w-96 w-full block py-2 px-3 border border-[#03C9D7] bg-white rounded-md shadow-sm focus:ring-[#03C9D7] focus:border-[#03C9D7] sm:text-sm"
                    onChange={(event) => {
                      formikProps.handleChange(event);
                      const desiredEmployerId = event.target.value;

                      const desiredEmployer = employer.find(
                        (employer) => employer.id === desiredEmployerId
                      );

                      if (desiredEmployer) {
                        setSelectedEmployer(desiredEmployer);
                        const employees = desiredEmployer.employee;
                        setEmployee(employees);
                      }
                    }}
                  >
                    <option value="" disabled>
                      Select an Employer
                    </option>
                    {employer?.map((option) => {
                      if (option?.name) {
                        return (
                          <option key={option?.id} value={option?.id}>
                            {option?.name}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </Field>

                  <ErrorMessage
                    name="employerField"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="lg:mt-0 mt-2 mr-2">
                  <label
                    htmlFor="employeeField"
                    className="block text-xl font-semibold text-gray-700 mr-2 mt-2"
                  >
                    Employee :
                  </label>
                  <Field
                    as="select"
                    id="employeeField"
                    name="employeeField"
                    className="mt-2 lg:w-96 w-full block py-2 px-3 border border-[#03C9D7] bg-white rounded-md shadow-sm focus:ring-[#03C9D7] focus:border-[#03C9D7] sm:text-sm"
                    onChange={(event) => {
                      formikProps.handleChange(event);
                      const desiredEmployeeId = event.target.value;

                      const desiredEmployee = employee.find(
                        (employer) => employer._id === desiredEmployeeId
                      );

                      if (desiredEmployee) {
                        setSelectedEmployee(desiredEmployee);
                      }
                    }}
                  >
                    <option value="" disabled>
                      Select an Employee
                    </option>
                    {employee.map((option) => (
                      <option key={option?._id} value={option?._id}>
                        {option?.first_name} {option?.sur_name}
                      </option>
                    ))}
                  </Field>

                  <ErrorMessage
                    name="employeeField"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="mt-4">
                  <label className="bg-[#03C9D7] hover:bg-[#03C9D7] text-white font-bold py-2 px-4 rounded-lg cursor-pointer ">
                    Upload Report
                    <input
                      type="file"
                      accept=".pdf, .doc, .docx, .xlsx"
                      className="hidden"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        formikProps.setFieldValue("reportFile", file);
                        setSelectedFile(file);
                      }}
                    />
                  </label>
                  {selectedFile && (
                    <div className="flex">
                      <p className="text-gray-600 text-base font-bold mt-2">
                        Selected Report:
                      </p>
                      <p className="text-gray-600 mt-2 ml-2">
                        {selectedFile.name}
                      </p>
                    </div>
                  )}
                  <ErrorMessage
                    name="reportFile"
                    component="div"
                    className="text-red-500 text-sm mt-2"
                  />
                </div>

                <div className="mt-4">
                  <button
                    type="submit"
                    className="bg-[#03C9D7] hover:bg-[#03C9D7] text-white font-bold py-2 px-4 rounded-lg cursor-pointer"
                  >
                    Submit
                  </button>
                </div>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <table className="border-collapse w-full mt-5">
                    <thead>
                      <tr>
                        <th className="border shadow-sm px-4 py-2 bg-[#03C9D7] text-white">
                          Employer Name
                        </th>
                        <th className="border shadow-sm px-4 py-2 bg-[#03C9D7] text-white">
                          Employee Name
                        </th>
                        <th className="border shadow-sm px-4 py-2 bg-[#03C9D7] text-white">
                          Report Name
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border shadow-sm px-4 py-2 text-center">
                          {selectedEmployer ? selectedEmployer?.name : "-"}
                        </td>
                        <td className="border shadow-sm px-4 py-2 text-center">
                          {selectedEmployee
                            ? `${selectedEmployee?.first_name} ${selectedEmployee?.sur_name}`
                            : "-"}
                        </td>
                        <td className="border shadow-sm px-4 py-2 text-center">
                          {data?.reportFile ? data?.reportFile?.name : "N/A"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="mt-10 flex items-end justify-end">
                    <Button
                      color="white"
                      bgColor={"#03C9D7"}
                      text="Download Report"
                      borderRadius="10px"
                    />
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Report;
