import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "../App.css";
import { Navbar, Sidebar, ThemeSettings } from "../components";
import Footer from "../components/Footer";
import { useStateContext } from "../contexts/ContextProvider";

const AppLayout = ({ children }) => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    themeSettings,
  } = useStateContext();

  const location = useLocation();
  const showSideMenu = location.pathname !== "/login";
  const showForgotPassword = location.pathname !== "/forgotPassword";
  const resetForgotPassword = location.pathname !== "/resetPassword";

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, [setCurrentColor, setCurrentMode]);

  return (
    <>
      <div className={currentMode === "Dark" ? "dark" : ""}>
        <div className="flex relative dark:bg-main-dark-bg">
          {showSideMenu &&
            showForgotPassword &&
            resetForgotPassword &&
            (activeMenu ? (
              <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                <Sidebar />
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <Sidebar />
              </div>
            ))}
          <div
            className={
              activeMenu &&
              showSideMenu &&
              showForgotPassword &&
              resetForgotPassword
                ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full"
                : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2"
            }
          >
            {showSideMenu && showForgotPassword && resetForgotPassword && (
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                <Navbar />
              </div>
            )}
            <div>
              {themeSettings && <ThemeSettings />}
              <Container className="middle_areacls" fluid>
                {children}
              </Container>
            </div>
            {showSideMenu && showForgotPassword && resetForgotPassword && (
              <Footer />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppLayout;
