import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { employersGrid } from "../data/dummy";
import { Header } from "../components";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../components/loader";

const Employer = () => {
  const toolbarOptions = ["Search"];

  const editing = { allowDeleting: true, allowEditing: true };
  const [loading, setLoading] = useState(true);

  const [employerData, setEmployerData] = useState([]);
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const headers = { Authorization: `Bearer ${token}` };
    axios
      .get(process.env.REACT_APP_API_LIVE_ADMIN + "get-employer", { headers })
      // .get("http://103.215.158.203:10314/admin/get-employer", { headers })
      // .get("http://192.168.1.203:8085/admin/get-employer", { headers })
      .then((response) => {
        if (response?.data?.data) {
          const formattedData = response?.data?.data.map((item) => {
            const formattedItem = {
              ...item,
              date: item?.date ? new Date(item?.date) : null,
              id: item?.id || "-",
              email: item?.email ? item?.email.toLowerCase() : "-",
              userType: item?.userType ? toNormalCase(item?.userType) : "-",
              firstname: item?.firstname ? toNormalCase(item?.firstname) : "-",
              businessName: item?.businessName
                ? toNormalCase(item.businessName)
                : "-",
              uifNumber: item?.uifNumber ? item?.uifNumber.toUpperCase() : "-",
              registrationNo: item?.registrationNo
                ? item?.registrationNo.replace(/\//g, "")
                : "-",
              mobileNumber: item?.mobileNumber || "-",
              sa_id_number: item?.sa_id_number || "-",
              address: item?.address ? toNormalCase(item?.address) : "-",
              province: item?.province ? toNormalCase(item?.province) : "-",
            };
            return formattedItem;
          });

          formattedData.reverse();

          setEmployerData(formattedData);
        }
      })
      .catch((error) => {
        if (error.response.data.status === 422) {
          toast.error(error?.response?.data?.error?.response?.error);
        } else {
          toast.error("Page not found!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  const toNormalCase = (text) => {
    return text.replace(
      /\b\w+/g,
      (match) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase()
    );
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Employers Management" />
      {loading ? (
        <Loader />
      ) : (
        <GridComponent
          dataSource={employerData}
          width="auto"
          allowPaging
          allowSorting
          pageSettings={{ pageCount: 5 }}
          editSettings={editing}
          toolbar={toolbarOptions}
          allowTextWrap
          allowMultiSorting
        >
          <ColumnsDirective>
            {employersGrid.map((item, index) => {
              return <ColumnDirective key={index} {...item} />;
            })}
          </ColumnsDirective>
          <Inject services={[Search, Page, Sort, Toolbar]} />
        </GridComponent>
      )}
      <style>{`
        .e-headercell .e-headercelldiv  {
          font-size: 0.875rem;
          background-color: #03C9D7; 
          color: white; 
          text-align: left; 
        }
        .e-grid .e-headercell {
          background-color: #03C9D7;
          text-align: left; 
        }
        .e-grid .e-rowcell {
          text-align: left; 
        }

        .e-pager .e-numericcontainer .e-active {
          background-color: #03C9D7;
          color: white;
        }
      `}</style>
    </div>
  );
};

export default Employer;
