import React from "react";
import { NavLink } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      await axios
        .post(process.env.REACT_APP_API_LIVE_ADMIN + "forgot-password", {
          // .post("http://103.215.158.203:10314/admin/forgot-password", {
          email: values.email,
        })
        .then((response) => {
          toast(response?.data?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((error) => {
          if (error.response.data.status === 422) {
            toast.error(error?.response?.data?.error?.response?.error);
          } else {
            toast.error("Page not found!");
          }
        });
    },
  });

  return (
    <div className="font-mono">
      <div className="container mx-auto">
        <div className="flex justify-center items-center h-screen px-6">
          <div className="w-full xl:w-3/4 lg:w-11/12 flex justify-center">
            <div className="w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none">
              <div className="px-8 mb-4 text-center">
                <h3 className="pt-4 mb-2 text-2xl">Forgot Your Password?</h3>
              </div>
              <form
                className="px-8 pt-6 pb-8 mb-4 bg-white rounded"
                onSubmit={formik.handleSubmit}
              >
                <div className="mb-4">
                  <label
                    className="block mb-2 text-sm font-bold text-gray-700"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    placeholder="Enter Email Address..."
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <p className="text-red-500 text-xs italic">
                      {formik.errors.email}
                    </p>
                  )}
                </div>
                <div className="mb-6 text-center">
                  <div className="mt-5">
                    <button
                      type="submit"
                      style={{ backgroundColor: "#03C9D7" }}
                      className={`text-white p-3 w-full hover:drop-shadow-xl rounded-md`}
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
                <hr className="mb-6 border-t" />
                <div className="text-center">
                  <NavLink
                    className="inline-block text-sm text-gray-500 align-baseline"
                    to="/login"
                  >
                    Already have an account? Login!
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
