import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "../../src/App.css";
import Loader from "../components/loader";
import UserTypePrivate from "./UserTypePrivate";
import UserTypeBusiness from "./UserTypeBusiness";

const PersonalDetails = () => {
  const { id } = useParams();
  const [personData, setPersonData] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const headers = { Authorization: `Bearer ${token}` };
    axios
      .get(process.env.REACT_APP_API_LIVE_ADMIN + `get-employer/${id}`, { headers })
      // .get(`http://103.215.158.203:10314/admin/get-employer/${id}`, { headers })
      // .get(`http://192.168.1.203:8085/admin/get-employer/${id}`, { headers })
      .then((response) => {
        if (response?.data?.data) {
          setPersonData(response?.data?.data);
        }
      })
      .catch((error) => {
        toast.error("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token, id]);
  if (!personData) {
    return null; // Return null or a loading state until data is available
  }

  const isBusinesseUser = personData.userType === "Business";

  if (loading) {
    return (
      <div className="container-fluid">
        <div className="flex h-full w-full items-center justify-center pt-10 pb-24">
          <div className="max-h-full w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-white overflow-hidden">
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  return <>{isBusinesseUser ? <UserTypeBusiness /> : <UserTypePrivate />}</>;
};

export default PersonalDetails;
